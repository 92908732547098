
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


#openJobs, #closedJobs, #archivedJobs {
  .row {
    display:flex;
    align-items: center;
    padding:0 10px;

    .action {
      text-align: center;
    }
  }
}

#download-report-btn {
  .dropdown-item {
    display:block;
    padding: 10px;
    text-decoration: none;

    &:hover {
      background-color: #93c54b;
      color: #ffffff;
    }
  }
}


.label.label-red {
  background-color: #ea1212;
  border: 1px solid #e40000;
}

.tab-content {
  margin-top:30px;

  #evidence, #signoff {
    .evidence {
      padding:14px;

      img {
        max-height:200px;
      }
    }
  }
}

.tbl__rooms {
  width:10%;
}

@media (max-width: 767px) {
  .tab-content {
    #evidence {
      .evidence {
        img {
          margin:0 auto;
        }

        .comment {
          text-align: center;
          margin-top:10px;
        }
      }
    }
  }
}

@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.rotating {
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -ms-animation: spin 2s linear infinite;
  -o-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

[v-cloak] {
  display: none;
}